<template>
  <div
    class="menu d-flex flex-column align-items-start justify-content-start menu_mm trans_300"
    :class="{ 'active': isActive }"
    v-click-outside="close"
  >
    <div
      class="menu_close_container"
      @click="close"
    >
      <div class="menu_close">
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="menu_search">
      <form @submit.prevent="search" class="header_search_form menu_mm">
        <input
          type="search"
          class="search_input menu_mm"
          placeholder="Search"
          v-model="query"
          required
        />
        <button
          class="header_search_button d-flex flex-column align-items-center justify-content-center menu_mm"
        >
          <font-awesome-icon icon="search" class="menu_mm" />
        </button>
      </form>
    </div>
    <nav class="menu_nav">
      <ul
        class="menu_mm"
        @click="close"
      >
        <li class="menu_mm">
          <router-link to="/">home</router-link>
        </li>

        <li
          class="menu_mm"
          v-for="(category, c) of categories"
          :key="c"
        >
          <router-link :to="`/categories/${getCategorySlug(category.canonical)}`">
            {{ category.name }}
          </router-link>
        </li>

        <li class="menu_mm">
          <router-link to="/terms-and-conditions">tnc</router-link>
        </li>
        <li class="menu_mm">
          <router-link to="/contact">contact</router-link>
        </li>
      </ul>
    </nav>
    <div class="menu_extra">
      <div class="menu_social">
        <ul>
          <li
            v-for="(link, l) of socialLinks"
            :key="l"
          >
            <a :href="link.link" v-html="link.icon" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    query: '',
    socialLinks: [
      {
        icon: require('@/assets/instagram.svg').default,
        link: 'https://www.instagram.com/brilcristz',
      },
      {
        icon: require('@/assets/facebook.svg').default,
        link: 'https://facebook.com/brilcrist',
      },
      {
        icon: require('@/assets/twitter.svg').default,
        link: 'https://twitter.com/agikun?lang=en',
      },
      {
        icon: require('@/assets/tumblr-square.svg').default,
        link: 'https://brilcrist.tumblr.com',
      },
      {
        icon: require('@/assets/pixiv.svg').default,
        link: 'https://www.pixiv.net/member.php?id=693103',
      },
    ],
  }),

  methods: {
    close() {
      this.$emit('menu-close');
    },
    search() {
      if (this.query) {
        this.close();
        this.$router.push({
          path: '/search',
          query: {
            q: this.query,
          },
        });
      }
    },
    getCategorySlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>

<style>
.menu_social li svg {
  width: 1.5em;
  height: 1.5em;
  fill: #9f9fa0;
}
</style>


<style lang="scss" scoped>
@import 'src/scss/partials/main-menu.scss';
</style>
