<template>
  <footer class="footer">
    <div
      class="footer_content"
      v-if="!$route.path.includes('/product')"
    >
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <div class="footer_logo">
                <router-link to="/">
                  <img src="../assets/brilcrist-logo.png" alt="brilcrist logo" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 footer_col mt-5">
              <div>
                <div>
                  <p>All artwork in this web belongs to their creators. You may not distribute, modify, transmit, reuse, repost, or use the content of this web without written permission from the artist.</p>
                </div>
                <div class="cards">
                  <div
                    v-for="(card, c) of cards"
                    :key="c"
                  >
                    <img :src="card" alt>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 footer_col mt-5">
              <ul>
                <li>
                  <router-link to="/">
                    home
                  </router-link>
                </li>

                <li
                  v-for="(category, c) of categories"
                  :key="c"
                >
                  <router-link :to="`/categories/${getCategorySlug(category.canonical)}`">
                    {{ category.name }}
                  </router-link>
                </li>

                <li>
                  <router-link to="/terms-and-conditions">
                    tnc
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact">
                    contact
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_social">
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div
                class="footer_social_container d-flex flex-row align-items-center justify-content-between"
              >
                <a
                  v-for="(link, l) of socialLinks"
                  :key="l"
                  :href="link.link"
                >
                  <div
                    class="footer_social_item d-flex flex-row align-items-center justify-content-start"
                  >
                    <div class="footer_social_icon" v-html="link.icon" />
                    <div class="footer_social_title">{{ link.text }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    cards: [
      require('@/assets/card_4.jpg'),
    ],
    questions: [
      'About us',
      'Track Orders',
      'Returns',
      'Jobs',
      'Shipping',
      'Partners',
      'Support',
      'Terms of Use',
      'Press',
    ],
    socialLinks: [{
      icon: require('@/assets/instagram.svg').default,
      text: 'instagram',
      link: 'https://www.instagram.com/brilcristz',
    }, {
      icon: require('@/assets/facebook.svg').default,
      text: 'facebook',
      link: 'https://facebook.com/brilcrist',
    }, {
      icon: require('@/assets/twitter.svg').default,
      text: 'twitter',
      link: 'https://twitter.com/agikun?lang=en',
    }, {
      icon: require('@/assets/tumblr-square.svg').default,
      text: 'tumblr',
      link: 'https://brilcrist.tumblr.com',
    }, {
      icon: require('@/assets/pixiv.svg').default,
      text: 'pixiv',
      link: 'https://www.pixiv.net/member.php?id=693103',
    }],
  }),

  methods: {
    getCategorySlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>

<style>
.footer_social_item:hover svg {
  fill: #f61b53;
}

.footer_social_icon svg {
  width: 2em;
  height: 2em;
  fill: #9f9fa0;
}
</style>


<style lang="scss" scoped>
@import 'src/scss/partials/foot-bar.scss';
</style>
