<template>
  <div class="super_container">
    <top-bar
      @menu-toggle="toggleMenu(!menuActive)"
      :categories="categories"
    />
    <main-menu
      :is-active="menuActive"
      :categories="categories"
      @menu-close="toggleMenu(false)"
    />
    <side-bar
      :categories="categories"
    />
    <router-view/>
    <foot-bar
      :categories="categories"
    />
  </div>
</template>

<script>
import TopBar from '@/partials/TopBar';
import MainMenu from '@/partials/MainMenu';
import SideBar from '@/partials/SideBar';
import FootBar from '@/partials/FootBar';

export default {
  components: {
    TopBar,
    MainMenu,
    SideBar,
    FootBar,
  },

  data: () => ({
    menuActive: false,
    categories: [],
  }),

  mounted() {
    this.fetchCategories();
  },

  methods: {
    async fetchCategories() {
      const { data } = await this.$api.categories();
      this.categories = data.results.map(
        ({ name, canonical }) => ({ name, canonical })
      );
    },
    toggleMenu(state) {
      this.menuActive = state;
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/main.scss';
</style>
