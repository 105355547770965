import Vue from 'vue';
import VeeValidate from 'vee-validate';
import './icons';
import App from './App';
import router from './router';
import store from './store';
import api from './api';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.prototype.$api = api;

Vue.use(VeeValidate);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
