<template>
  <div class="sidebar">
    <div class="sidebar_logo">
      <router-link to="/">
        <img src="../assets/brilcrist-logo.png" alt="brilcrist logo" />
      </router-link>
    </div>

    <nav class="sidebar_nav">
      <ul>
        <li>
          <router-link to="/">
            <span>home</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>

        <li
          v-for="(category, c) of categories"
          :key="c"
        >
          <router-link :to="`/categories/${getCategorySlug(category.canonical)}`">
            <span>{{ category.name }}</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>

        <li>
          <router-link to="/terms-and-conditions">
            <span>tnc</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <router-link to="/contact">
            <span>contact</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="search">
      <form @submit.prevent="search" class="search_form">
        <input
          type="text"
          class="search_input"
          placeholder="Search"
          v-model="query"
          required
        />
        <button class="search_button">
          <font-awesome-icon icon="search" />
        </button>
      </form>
    </div>

    <router-link
      to="/cart"
      class="cart d-flex flex-row align-items-center justify-content-start"
    >
      <div class="cart_icon">
        <a>
          <img src="@/assets/bag.png" alt>
          <div class="cart_num">{{ cartQty }}</div>
        </a>
      </div>
      <div class="cart_text">bag</div>
      <div class="cart_price">${{ cartPrice }} ({{ cartItems }})</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    query: '',
  }),

  computed: {
    cartQty() {
      return this.$store.state.cartQty;
    },
    cartPrice() {
      return this.$store.state.cartPrice;
    },
    cartItems() {
      return this.$store.state.cartItems;
    },
  },

  methods: {
    search() {
      if (this.query) {
        this.$router.push({
          path: '/search',
          query: {
            q: this.query,
          },
        });
      }
    },
    getCategorySlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/partials/side-bar.scss';
</style>
