import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ './views/Home'),
    },
    {
      path: '/cart',
      component: () => import(/* webpackChunkName: "cart" */ './views/Cart'),
    },
    {
      path: '/categories/:slug',
      component: () => import(/* webpackChunkName: "categories" */ './views/Categories'),
    },
    {
      path: '/product/:slug',
      component: () => import(/* webpackChunkName: "product" */ './views/Product'),
    },
    {
      path: '/checkout',
      component: () => import(/* webpackChunkName: "checkout" */ './views/Checkout'),
    },
    {
      path: '/order/:token/detail',
      component: () => import(/* webpackChunkName: "complete" */ './views/Order'),
    },
    {
      path: '/terms-and-conditions',
      component: () => import(/* webpackChunkName: "tnc" */ './views/TNC'),
    },
    {
      path: '/contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact'),
    },
    {
      path: '/search',
      component: () => import(/* webpackChunkName: "search" */ './views/Search'),
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
