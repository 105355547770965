import axios from 'axios';

const request = axios.create({
  baseURL: process.env.BACKEND,
  timeout: 30000,
});

const api = {
  banners: async params => await request.get('/banner', { params }),
  categories: async params => await request.get('/category', { params }),
  category: async (slug, params) => await request.get(`/category/${slug}`, { params }),
  products: async params => await request.get('/product', { params }),
  product: async (slug, params) => await request.get(`/product/${slug}`, { params }),
  tags: async (tag, params) => await request.get(`/tag/${tag}`, { params }),
  shippingMethods: async (id, params) => await request.get(`/${id}`, { params }),
  details: async (token, params) => await request.get(`/order/${token}/detail`, params),
  voucher: async (params) => await request.post('/voucher/', params),
};

export default api;
