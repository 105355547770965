<template>
  <header class="header">
    <div class="header_content d-flex flex-row align-items-center justify-content-start">
      <div
        class="hamburger menu_mm"
        @click.stop="$emit('menu-toggle')"
      >
        <font-awesome-icon icon="bars" class="menu_mm" />
      </div>

      <div class="header_logo">
        <router-link to="/">
          <img src="../assets/brilcrist-logo.png" alt="brilcrist logo" />
        </router-link>
      </div>

      <nav class="header_nav">
        <ul class="d-flex flex-row align-items-center justify-content-start">
          <li>
            <router-link to="/">home</router-link>
          </li>
          <li
            v-for="(category, c) of categories"
            :key="c"
          >
            <router-link :to="`/categories/${getCategorySlug(category.canonical)}`">
              {{ category.name }}
            </router-link>
          </li>
          <li>
            <router-link to="/terms-and-conditions">tnc</router-link>
          </li>
          <li>
            <router-link to="/contact">contact</router-link>
          </li>
        </ul>
      </nav>

      <div class="header_extra ml-auto d-flex flex-row align-items-center justify-content-start">
        <div class="cart d-flex flex-row align-items-center justify-content-start">
          <div class="cart_icon">
            <router-link to="/cart">
              <img src="@/assets/bag.png" alt>
              <div class="cart_num">{{ cartQty }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    cartQty() {
      return this.$store.state.cartQty;
    },
  },

  methods: {
    getCategorySlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>


<style lang="scss" scoped>
@import 'src/scss/partials/top-bar.scss';
</style>
