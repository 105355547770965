import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Noty from 'noty';

Vue.use(Vuex);

const recalculateCart = (state) => {
  let qty = 0;
  let price = 0;
  let weight = 0;
  for (let i = 0; i < state.cart.length; i++) {
    let productPrice = state.cart[i].discount_price
      ? state.cart[i].discount_price
      : state.cart[i].regular_price;
    qty += state.cart[i].qty;
    price += state.cart[i].qty * productPrice;
    weight += state.cart[i].qty * state.cart[i].weight;
  }

  state.cartQty = qty;
  state.cartPrice = parseFloat(price.toFixed(2));
  state.cartItems = state.cart.length;
  state.cartWeight = weight;
};

export default new Vuex.Store({
  state: {
    cart: [],
    cartQty: 0,
    cartPrice: 0,
    cartItems: 0,
    cartWeight: 0,
  },
  mutations: {
    ADD_TO_CART(state, item) {
      const exists = state.cart.find(i => i.canonical === item.canonical);
      if (exists) {
        if (exists.qty < exists.quantity) exists.qty += 1;
      } else {
        const insert = JSON.parse(JSON.stringify(item));
        insert.qty = 1;
        state.cart.push(insert);
        new Noty({
          text: `Product <strong>${item.name}</strong> added to cart`,
          timeout: 5000,
          type: 'success',
        }).show();
      }
      recalculateCart(state);
    },
    DECREMENT_CART_ITEM(state, item) {
      const exists = state.cart.find(i => i.canonical === item.canonical);
      if (exists.qty > 1) {
        exists.qty -= 1;
      } else {
        this.commit('REMOVE_CART_ITEM', item);
      }
      recalculateCart(state);
    },
    INCREMENT_CART_ITEM(state, item) {
      const exists = state.cart.find(i => i.canonical === item.canonical);
      if (exists.qty < exists.quantity) exists.qty += 1;
      recalculateCart(state);
    },
    REMOVE_CART_ITEM(state, item) {
      const index = state.cart.findIndex(i => i.canonical === item.canonical);
      state.cart.splice(index, 1);
      // lock_by items
      state.cart
        .filter(i => i.lock_by === item.sku)
        .forEach(i => {
          this.commit('REMOVE_CART_ITEM', i);
        });
    },
    CLEAR_CART(state) {
      state.cart = [];
      state.cartQty = 0;
      state.cartPrice = 0;
      state.cartItems = 0;
      state.cartWeight = 0;
    },
  },
  plugins: [createPersistedState()],
});
